// extracted by mini-css-extract-plugin
export var authorHead__aboutAuthorDescription = "d1_HD";
export var authorHead__aboutAuthorTitle = "d1_HC";
export var authorHead__aboutAuthorWrapper = "d1_HB";
export var authorHead__description = "d1_Hy";
export var authorHead__flexWrapper = "d1_Ht";
export var authorHead__name = "d1_Hw";
export var authorHead__photo = "d1_Hv";
export var authorHead__position = "d1_Hx";
export var authorHead__socialWrapper = "d1_Hz";
export var authorHead__wrapper = "d1_Hs";